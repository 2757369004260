@tailwind base;
@tailwind components;
@tailwind utilities;

/* Utility for truncating lines */
.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}